<template>
    <div class="container">
        <div class="align-items-center">

            <div class="col-lg-6">
                <img class="image" src="../assets/login.png" />
            </div>
            <div class="col-lg-6 login">
                <h1>Hey! Welcome
                    to Aurora</h1>
                <h3>Log in to your account</h3>
                <p>Email</p>
                <input type="email" v-model="email" placeholder=" Enter Email">
                <p>Password</p>
                <input type="password" v-model="password" placeholder="Enter Password">
                <div><a href="reset-password" data-factors-click-bind="true">Forgot your password?</a></div>
                <button @click="signInWithEmailPassword" type="submit" class="style_btn" data-factors-form-bind="true"
                    data-factors-click-bind="true">Login</button>
                <div class="separator">
                    <hr class="solid1">
                    <p class="line">OR</p>
                    <hr class="solid1">
                </div>
                <div class="style_social_auth_wrapper">
                    <p class="style_social_auth_label"></p>
                    <ul class="style_social_auth_container">
                        <li class="style_slbtn style_sl_google" id="" @click="signInWithGoogle">
                            <img class="social-image" src="https://img.icons8.com/color/144/null/google-logo.png" />
                            <span class="social-title">Google</span>
                        </li>
                        <!-- <li class="style_slbtn style_sl_facebook" id="id-google">
                            <img class="social-image" src="https://img.icons8.com/fluency/144/null/facebook.png" />
                            <span class="social-title">Facebook</span>
                        </li> -->
                        <li class="style_slbtn style_sl_linkedin" id="id-google">
                            <img class="social-image" src="https://img.icons8.com/fluency/144/null/linkedin.png" />
                            <span class="social-title">Linkedin</span>
                        </li>
                    </ul>
                </div>
                <div class="sign-up-text"><span>Don't have an account yet? </span><a href="/signin"
                        data-factors-click-bind="true"><span class="">Sign up</span></a></div>
            </div>

        </div>

    </div>
    <transition name="toast">
        <Toast v-bind:message="toastMessage" v-if="showToast" />
    </transition>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, signOut } from "firebase/auth";
import { app } from '@/firebase'
import consts from '../const'
import { getAnalytics, logEvent } from "firebase/analytics";
import Toast from '../components/Toast.vue'
import { ref } from 'vue'

export default {
    name: 'LoginVue',
    components: {
        Toast
    },
    setup() {
        const showToast = ref(false)
        const toastMessage = ref('')


        const triggerToast = (message) => {
            toastMessage.value = message
            showToast.value = true;
            setTimeout(() => showToast.value = false, 3000)
        }

        return { showToast, toastMessage, triggerToast }
    },
    mounted() {
    },
    data() {
        return {
            user: {},
            email: '',
            password: '',
            selectedAnswers: [],
            scoresList: [],
            bubbleData: [],
            headers: {
                'Authorization': `Token ${process.env.VUE_APP_API_AUTHORIZATION}`
            }
        }
    },
    methods: {
        signInWithEmailPassword() {
            let postObject = {
                "email": this.email,
                "password": this.password
            }
            if (!this.email.match(consts.validRegex)) {
                this.triggerToast('Enter a valid E-mail Address')
            } else if (this.password == '') {
                this.triggerToast('Password is required')
            } else {
                this.logIn(postObject)
            }
        },
        logIn(postObject) {
            axios.post(process.env.VUE_APP_BASE_URL + 'login/', postObject, { headers: this.headers })
                .then((response) => {
                    this.user = new UserModel(response.data)
                    localStorage.setItem('userObject', JSON.stringify(response.data));
                    console.log('(response.data.user.stage ', response.data.user.stage)
                    if (response.data.statusCode == 200) {
                        console.log('(response.data.user.stage ', response.data.user.stage)
                        this.selectedAnswers = JSON.parse(localStorage.getItem('selectedAnswer'))
                        this.bubbleData = JSON.parse(localStorage.getItem('bubbleIds'))
                        if (this.selectedAnswers) {
                            console.log('(response.data.user.stage ', response.data.user.stage)
                            this.calculateFinalScore(response.data.user.id)
                            // this.calculateScore(response.data.user.id)
                        } else {
                            console.log('(response.data.user.stage ', response.data.user.stage)
                            if (response.data.user.stage == 1) {
                                this.$router.push('authentication')
                            } else if (response.data.user.stage == 2) {
                                this.$router.push('score')
                            }
                        }
                    } else {
                        this.triggerToast(response.data.message)
                    }
                })
                .catch((error) => {
                    console.log('error', error)
                })

        },
        signInWithGoogle() {
            const auth = getAuth(app);
            const provider = new GoogleAuthProvider(app)
            signInWithPopup(auth, provider)
                .then((result) => {
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    const user = result.user;
                    console.log('data', user, user.email)
                    let postObject = {
                        "email": user.email,
                        "password": user.uid
                    }
                    this.logIn(postObject)
                    this.logOutGoogle()
                }).catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // const email = error.customData.email;
                    console.log('error', errorMessage, errorCode)
                    const credential = GoogleAuthProvider.credentialFromError(error);
                });
        },
        // signInWithFacebook() {
        //     const auth = getAuth(app);
        //     const provider = new FacebookAuthProvider(app);
        //     signInWithPopup(auth, provider)
        //         .then((result) => {
        //             const user = result.user;
        //             const credential = FacebookAuthProvider.credentialFromResult(result);
        //             const accessToken = credential.accessToken;

        //             // ...
        //         })
        //         .catch((error) => {
        //             const errorCode = error.code;
        //             const errorMessage = error.message;
        //             const email = error.customData.email;
        //             console.log('error', errorMessage, errorCode)
        //             const credential = FacebookAuthProvider.credentialFromError(error);

        //             // ...
        //         });

        // }

        // calculateScore(userId) {
        //     let score = 0
        //     let total = 0
        //     total = this.selectedAnswers.length * 50
        //     this.selectedAnswers.forEach(element => {
        //         score += element.answer * 10
        //     });
        //     let finalScore = Math.round((score / total) * 100)
        //     this.updateUserAssessmentScore(finalScore, userId)
        // },

        calculateScoreNew(id) {
            let score = 0
            this.selectedAnswers.forEach(element => {
                if (element.bubble_id == id) {
                    score += element.answer
                }
            })
            return score * 10
        },

        calculateFinalScore(userId) {
            this.bubbleData.forEach(element => {
                var score = {
                    'bubble_id': element.id,
                    'bubble_name': element.name,
                    'score': this.calculateScoreNew(element.id)
                }
                this.scoresList.push(score)
            })
            this.updateUserAssessmentScore(JSON.stringify(this.scoresList), userId)
        },

        updateUserAssessmentScore(finalScore, userId) {
            axios.put(process.env.VUE_APP_BASE_URL + `user/${userId}/`, { "assessment_score": finalScore }, { headers: this.headers })
                .then((response) => {
                    if (response.data.stage == 1) {
                        this.$router.push('authentication')
                    } else if (response.data.stage == 2) {
                        this.$router.push('score')
                    }
                })
                .catch((error) => {
                    console.log('error', error)
                })
        },


        logOutGoogle() {
            const auth = getAuth(app);
            signOut(auth).then(() => {
                console.log('sig out')
            }).catch((error) => {
                console.log('sig out failed')
            });
        }
    }
}

class UserModel {
    constructor(data) {
        this.data = data
    }
    // and so on, put other methods here
}

</script>



<style>

</style>