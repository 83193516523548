<template>
    <div class="toast-wrapper">
        <div :style="{ 'background': color }" class="toast">
            {{ message }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ToastVue',
    props: ['message', 'color'],
}
</script>
  
<style>
.toast-wrapper {
    position: fixed;
    width: 100%;
    top: 20px;
}

.toast {
    text-align: center;
    padding: 20px;
    color: white;
    background: red;
    border-radius: 10px;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    max-width: 50%;
    margin: 20px auto;
}


/* enter transitions */
.toast-enter-active {
    animation: wobble 0.5s ease;
}

/* leave transitions */
.toast-leave-to {
    opacity: 0;
    transform: translateY(-60px);
}

.toast-leave-active {
    transition: all 0.3s ease;
}

@keyframes wobble {
    0% {
        transform: translateY(-100px);
        opacity: 0
    }

    50% {
        transform: translateY(0px);
        opacity: 1
    }

    60% {
        transform: translateX(8px);
        opacity: 1
    }

    70% {
        transform: translateX(-8px);
        opacity: 1
    }

    80% {
        transform: translateX(4px);
        opacity: 1
    }

    90% {
        transform: translateX(-4px);
        opacity: 1
    }

    100% {
        transform: translateX(0px);
        opacity: 1
    }
}
</style>