<template>
    <transition name="toast">
        <Toast v-bind:message="toastMessage" v-if="showToast" />
    </transition>
    <div class="bubble-view">
        <h2>What do you want to improve upon?</h2>
        <p>Select upto 5 aspects of your daily life, you want to
            improve upon. <br>We will curate a personalized list of tasks
            to help you achieve your goals</p>

        <div style="font-size: 12px;position: absolute; top: 105px; right: 30px;">Est. Time for Assessment : <span
                style="color: red; font-weight: bold;">{{
                    completionTime
                }} secs</span></div>

        <div style="margin-top: 50px; margin-bottom: 50px;">
            <transition enter-active-class="animate__animated animate__bounce" leave-active-class="">
                <div class="bubble-container">
                    <div v-if="bubbleList.length == 0" class="loading loading--full-height"></div>
                    <div v-else v-for="(bubble, index) in bubbleList" :key="index" :id="bubble.name"
                        @click="clickOnBubble(index)"
                        :style="{ 'height': deviceType == 'mobile' ? '100px' : bubble.height, 'width': deviceType == 'mobile' ? '100px' : bubble.width }"
                        v-bind:class="bubble.is_selected ? 'bubble-active' : 'bubble'">
                        {{ bubble.name }}
                    </div>

                </div>
            </transition>
        </div>
        <p>* Please read the disclaimer before taking the assessment</p>
        <button type="submit" @click="clickOnProceed" class="style_btn">Start Assessment</button>
        <p style="margin-top: 10px; margin-top: 100px;">Disclaimer</p>
        <p style="font-size: 10px;">We are doing our best to prepare the content of this site. However, Playonomics
            Skills cannot guarantee the
            expressions and suggestions of the contents, as well as its accuracy. In addition, to the extent permitted
            by the law, Playonomics Skills shall not be responsible for any losses and/or damages due to the usage of
            the information on our website.The links contained on our website may lead to external sites, which are
            provided for convenience only. Any
            information or statements that appeared in these sites are not sponsored, endorsed, or otherwise approved by
            Playonomics Skills. For these external sites, Playonomics Skills cannot be held liable for the availability
            of, or the content located on or through it. Plus, any losses or damages or medical emergencies occurred
            from using these contents or the internet generally</p>


    </div>
    <div style="padding: 5px; text-align: center;margin-top: 20px;" class="footer">
        <h1 style="margin-bottom: 0px">
            Playonomics
        </h1>
        <p style="margin-top: 5px">We have built an AI & SaaS-based feedback and recommendation platform for your
            teams.</p>

    </div>
    <div style="padding-bottom: 20px;background-color: #3e2682; display: flex;" class="center-items">
        <a style="margin-left: 20px; text-decoration: underline #fff; color:white" href="/privacy-policy" target="">
            <p>Privacy Policy</p>
        </a>
        <a style="margin-left: 20px; text-decoration: underline #fff;color:white" href="/terms&conditions" target="">
            <p>Terms & Conditions</p>
        </a>
    </div>
    <div
        style="width: 100%; text-align: center; padding: 15px 0px; background-color: #3e2682; color: white; margin-top: 0.3px;">
        ©
        2023 by
        Playonomics
    </div>
    <!-- <Footer /> -->
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import consts from '../const'
import Toast from '../components/Toast.vue'
import { ref } from 'vue'
// import Footer from '../components/Footer.vue'
export default {
    components: {
        Toast,
        // Footer
    },
    setup() {
        const showToast = ref(false)
        const toastMessage = ref('')


        const triggerToast = (message) => {
            toastMessage.value = message
            showToast.value = true;
            setTimeout(() => showToast.value = false, 3000)
        }

        return { showToast, toastMessage, triggerToast }
    },
    data() {
        return {
            bubbleList: [],
            selectedBubblesIds: [],
            completionTime: 0,
            deviceType: window.innerWidth <= 900 ? 'mobile' : 'desktop',
            headers: {
                'Authorization': `Token ${process.env.VUE_APP_API_AUTHORIZATION}`
            }
        }
    },
    mounted() {
        this.getAllBubbles()
    },

    methods: {
        clickOnBubble(index) {
            this.bubbleList[index].is_selected = !this.bubbleList[index].is_selected
            if (this.selectedBubblesIds.indexOf(this.bubbleList[index]) === -1) {
                this.selectedBubblesIds.push(this.bubbleList[index])
                this.completionTime += 30;
            } else {
                var i = this.selectedBubblesIds.indexOf(this.bubbleList[index]);
                this.selectedBubblesIds.splice(i, 1)
                this.completionTime -= 30;

            }

        },
        getAllBubbles() {
            axios.get(process.env.VUE_APP_BASE_URL + 'bubble/', { headers: this.headers })
                .then((response) => {
                    this.bubbleList = response.data.data
                })
                .catch((error) => {
                })
        },

        clickOnProceed() {
            if (this.selectedBubblesIds.length == 0) {
                this.triggerToast('Please select at least 1 bubble')
            } else {
                localStorage.setItem('bubbleIds', JSON.stringify(this.selectedBubblesIds));
                this.$router.push('questions')
            }
        }
    }


}

</script>

<style>
.bubble-view {
    padding: 0px 20px;
    text-align: center;
}

.bubble-container {
    /* display: flex; */
    flex-wrap: wrap;
    /* below code is to center align */
    /* width: 80%;
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative; */
    /* height: 200px; */
    /* background: gray; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.bubble:hover {
    /* background-image: linear-gradient(#460265,#460265); */
    cursor: pointer;
}

.bubble {
    /* background-image: linear-gradient(#460265, #aa49d7, #d6b7e4); */
    background-color: #f4e7fa;
    border: 1px solid #f4e7fa;
    display: flex;
    color: #3a267c;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-size: 14px;
    text-align: center;
    padding: 10px 20px;
    /* margin: 10px; */
    border-radius: 50%;
    /* border: 1px solid rgb(224, 223, 223); */
    box-shadow: #dcdada 10px 10px 10px 2px;
    -webkit-tap-highlight-color: transparent;
}

.bubble-active {
    /* background: #460265; */
    /* background-image: linear-gradient(#3a267c, #460265, #d6b7e4); */
    background-image: linear-gradient(to right, #1e2265, #3e2682, #5a2a9a);
    display: flex;
    color: #fff;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-size: 15px;
    text-align: center;
    padding: 10px 20px;
    /* margin: 10px; */
    border-radius: 50%;
    /* border: 1px solid rgb(239, 157, 35); */
    /* to remove click effect on touch devices */
    -webkit-tap-highlight-color: transparent;
}

.bubble-active:hover {
    cursor: pointer;

}
</style>