<template>
  <!-- <nav>
    <div :style="{ 'padding': deviceType == 'mobile' ? '30px' : '10px 30px' }" class="nav-bar">
      <a href="/">
        <div class="logo">
          <img src="./assets/logo_new.png" height="40" />
        </div>
      </a>

      <div :style="{ 'top': deviceType == 'mobile' ? '60px' : '' }"
        style="height: 40px;font-size: 14px;display: flex;flex-direction: row;position: absolute;right: 30px;">
        <p> <a class="route-anchor" href="/learning">Learnings</a></p>
        <p> <a class="login-button" href="/authentication">Login</a></p>
      </div>
    </div>
  </nav> -->
  <Header v-if="!$route.meta.hideNavbar" />
  <!-- <HomeView/> -->
  <router-view />
</template>

<script>
/* eslint-disable */
import Header from './components/Header.vue'
import HomeView from './views/HomeView.vue';
export default {
  components: { Header, HomeView },
  data() {
    return {
      deviceType: window.innerWidth <= 800 ? 'mobile' : 'desktop',
    }
  }
}
</script>

<style>
#app {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  color: #2c3e50;
}

body {
  display: block;
  margin: 0px;
}

/* nav {
  padding: 30px;
} */

nav a {
  /* font-weight: bold; */
  color: transparent;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.nav-bar {
  display: flex;
  background-color: #3e2682;
  flex-direction: column;
}

.login-button {
  background-color: white;
  color: #3e2682;
  padding: 5px 15px;
  margin-left: 30px;
  text-decoration: none;
  border-radius: 50px;
}

.login-button:hover {
  background-color: white;
  color: #3e2682;
  padding: 5px 15px;
  margin-left: 30px;
  text-decoration: none;
  border-radius: 50px;
  cursor: pointer;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
}

h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.style_btn {
  background-image: linear-gradient(to right, #1e2265, #3e2682, #5a2a9a);
  border: 1px solid #fafafa;
  border-radius: 30px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  height: 38px;
  margin: 20px 0;
  min-width: 200px;
}

.center-items {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* #3e2682 primary color */
</style>
