<template>
    <div class="home">
        <div v-if="deviceType == 'desktop'" class="header"
            :style="{ 'flex-direction': deviceType == 'mobile' ? 'column' : 'row' }">
            <div>
                <div style="text-align: center;" class="text-header">Intelligence Quotient (IQ) is not
                    enough,
                    we are here to help you
                    build Emotional and Social Intelligence (EQ) at workplaces.</div>
                <div class="center-items" style="margin-top: 30px;">
                    <a style="font-weight: 600; font-size: 18px;" class="login-button" href="/selection">Take
                        Assessment</a>
                </div>
            </div>
            <div class="center-items" style="width: 60%;">
                <img src="../assets/home.png" :style="{ 'height': deviceType == 'mobile' ? '200px' : '400px' }" />
            </div>
        </div>

        <div v-else class="header" style="display: flex; flex-direction: column;">
            <div class="center-items" style="width: 60%;">
                <img src="../assets/home.png" :style="{ 'height': deviceType == 'mobile' ? '200px' : '400px' }" />
            </div>
            <div>
                <div style="text-align: center;" class="text-header">Intelligence Quotient (IQ) is not
                    enough,
                    we are here to help you
                    build Emotional and Social Intelligence (EQ) at workplaces.</div>
                <div class="center-items" style="margin-top: 30px;">
                    <a style="font-weight: 600; font-size: 18px;" class="login-button" href="/selection">Take
                        Assessment</a>
                </div>
            </div>
        </div>

        <div class="content">
            <h1 class="content-title">Aurora</h1>
            <div class="image-parent">
                <img class="image1"
                    src="https://static.wixstatic.com/media/11062b_4cbde0f75a55484999188324b2c08ace~mv2.jpg/v1/fill/w_1416,h_854,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Home%20office.jpg"
                    alt="Home office" fetchpriority="high">

                <img class="image2"
                    src="https://static.wixstatic.com/media/fabc7a_ae4e7a78a7924ff2b0368382a5e1630d~mv2.jpg/v1/fill/w_507,h_285,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/tool.jpg">
                <img class="image3"
                    src="https://static.wixstatic.com/media/fabc7a_805b1fb06f28490ba51f32c4f4d0ca78~mv2.png/v1/crop/x_0,y_0,w_922,h_1548/fill/w_285,h_479,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Screenshot%202022-08-22%20at%204_21_57%20PM.png">
                <img class="image4"
                    src="https://static.wixstatic.com/media/fabc7a_0d8cc26862374320b43ab4a67512ea1e~mv2.png/v1/fill/w_288,h_407,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/13.png">


            </div>
            <!-- <p style="font-size: 16px;">Our goal is to enhance your work culture quotient by helping your
                teams<br>improve their overall emotional
                intelligence.</p>
            <p style="font-size: 16px;">You manage your emotions just like you manage work tasks.</p> -->

            <!-- <h1 class="content-title">MY EQ dashboard</h1>
            <div>
                <img :style="{ 'width': deviceType == 'mobile' ? '90%' : '35%' }"
                    style="height: 650px;object-fit: cover;object-position: 50% 50%;"
                    src="https://static.wixstatic.com/media/fabc7a_9a8a0d87b86046b28345c04ed72c2e0c~mv2.png/v1/fill/w_690,h_977,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/5.png" />
                <img :style="{ 'width': deviceType == 'mobile' ? '90%' : '35%' }"
                    style="height: 650px;object-fit: cover;object-position: 50% 50%;"
                    src="https://static.wixstatic.com/media/fabc7a_3c00b156407e4b2183c534877e185fac~mv2.png/v1/fill/w_714,h_1016,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/People%20%26%20Culture%20Proposal.png" />
            </div> -->
            <div class="footer">
                <h1 style="margin-bottom: 0px">
                    Playonomics
                </h1>
                <p style="margin-top: 5px;margin-bottom: 0px;">We have built an AI & SaaS-based feedback and
                    recommendation platform for
                    your
                    teams.</p>
                <!-- <div style="display: flex; margin-top: 20px; flex-direction: column;">
                    <div>
                        <h4 style="margin-bottom: 0px">www.priyankabotny.com</h4>
                        <p style="margin-top: 5px">Founder, Playonomics LLP</p>
                    </div>
                </div> -->
                <!-- <div style="width: 250px; margin: auto; margin-top: 20px;">
                    <p style="text-align: left;">Email</p>
                    <input type="email" placeholder="Enter Email">
                </div> -->
            </div>
            <div style="padding-top: 20px;background-color: #3e2682;">
                <a style="margin-left: 20px;" href=" https://www.facebook.com/Playonomics-101111142312310"
                    target="_blank"><img src="https://img.icons8.com/ios-filled/50/FFFFFF/null/facebook-new.png"
                        width="25" /></a>
                <a style="margin-left: 20px;" href="https://www.instagram.com/playonomics/" target="_blank"><img
                        src="https://img.icons8.com/ios-filled/50/FFFFFF/instagram-new--v1.png" width="25" /></a>
                <a style="margin-left: 20px;" href="mailto:contact@playonomic.com" target="_blank"><img
                        src="https://img.icons8.com/ios-filled/50/FFFFFF/apple-mail.png" width="25" /></a>
            </div>

            <div style="padding-bottom: 20px;background-color: #3e2682; display: flex;" class="center-items">
                <a style="margin-left: 20px; text-decoration: underline #fff;color:white" href="/privacy-policy"
                    target="">
                    <p>Privacy Policy</p>
                </a>
                <a style="margin-left: 20px; text-decoration: underline #fff;color:white" href="/terms&conditions"
                    target="">
                    <p>Terms & Conditions</p>
                </a>
            </div>
            <div
                style="width: 100%; text-align: center; padding: 15px 0px; background-color: #3e2682; color: white; margin-top: 0.3px;">
                ©
                2023 by
                Playonomics
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import BubbleSelection from '@/views/BubbleSelectionView.vue'
import Login from '@/components/Login.vue'

export default {
    name: 'HomeView',
    components: {
        BubbleSelection,
        Login
    },
    data() {
        return {
            deviceType: window.innerWidth <= 900 ? 'mobile' : 'desktop',
        }
    },
}
</script>

<style>
/* Home Page Styles */
.parallax {
    background-image: url("https://static.wixstatic.com/media/11062b_f68602886d71455a85a9705d586b75a0~mv2.jpg/v1/fill/w_1470,h_902,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_f68602886d71455a85a9705d586b75a0~mv2.jpg");
    height: 700px;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo {
    display: flex;
}

.logo-name {
    /* background-color: black; */
    color: white;
    /* height: 35px; */
    /* width: 140px; */
    margin-left: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* text-align: center; */
}

.route-container {
    height: 40px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 30px;

}

.header {
    height: 600px;
    background-color: #3e2682;
    display: flex;
    align-items: center;
    justify-content: center;
}

.route-anchor {
    margin-left: 25px;
    color: rgb(254, 254, 254);
    /* background-color: black; */
    font-size: 15px;
    /* border-right: 3px #fff solid; */
    text-decoration: none;
    /* padding: 10px 15px; */
}

.route-anchor:hover {
    margin-left: 25px;
    color: #eae9f1;
    font-size: 16px;
    border-bottom: 1px #e0dee7 solid;
    text-decoration: none;
    /* padding: 10px 15px; */
}

.text-header {
    font-size: 1.5em;
    line-height: 35px;
    color: white;
    margin-top: 50px;
    margin-left: 30px;
}

.content {
    text-align: center;
}

.content-title {
    font-size: 1.5em;
    color: black;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    padding-top: 50px;
}

.footer {
    margin-top: 100px;
    padding-top: 10px;
    background-color: #3e2682;
    color: white;
}

.image-parent {
    position: relative;
    top: 0;
    left: 0;
}

.image1 {
    position: relative;
    top: 0;
    left: 0;
    width: 80%;
    height: 70%;
    object-fit: cover;
    object-position: 50% 50%;
}

.image2 {
    position: absolute;
    width: 31%;
    height: 38%;
    top: 24%;
    left: 35.2%;
}

.image3 {
    position: absolute;
    width: 19%;
    height: 58%;
    top: 11%;
    left: 62%;
}

.image4 {
    position: absolute;
    width: 19%;
    height: 57%;
    top: 16%;
    left: 62%;
}





/* common styles */
input,
input[type=email],
input[type=password],
input[type=search],
input[type=text],
textarea {
    width: 100%;
    float: left;
    padding: 0 15px;
    height: 48px;
    line-height: 48px;
    background: #fff;
    border: 1px solid #e0e0e1;
    border-radius: 10px;
    color: #121213;
    font-size: 15px;
    margin-bottom: 20px;
    transition: all .5s ease-in-out;
}

.social-title {
    width: 20%;
    /* margin-left:10%; */
    /* text-align: left; */
    float: left;
}

.social-image {
    width: 20px;
    margin-right: 5%;
    /* text-align:right; */
    float: left;
}

.container {
    /* width: 100%; */
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}


.align-items-center {
    align-items: center !important;
}


.row {
    display: flex;
    flex-wrap: wrap;
    display: table;
    clear: both;
}


.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    /* width: 40%; */
    min-height: 1px;
    max-width: 75%;
    float: left;
    /* overflow-y: auto; */
    padding-right: 15px;
    margin: 20px;
    padding: 15px;
}



.login {
    text-align: left;
}

/* .style_btn {
    background-image: linear-gradient(to right, #1e2265, #3e2682, #5a2a9a);
    border-radius: 30px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 40px;
    margin: 20px 0px;
    -webkit-tap-highlight-color: transparent;
} */

.line {
    margin: 10px;
    position: relative;
    text-align: center;
}

.separator {
    display: flex;
    align-items: center;
}

hr.solid1 {
    flex: 1;
    border-top: 1px solid rgb(195, 195, 195);
    border-bottom: 1px solid rgb(195, 195, 195);
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.style_social_auth_wrapper {
    margin-top: 4px;
}

.style_social_auth_label {
    color: #35495e;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    margin-right: 20px;
    margin-top: 0;
}

.style_social_auth_container {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.style_sl_google {
    background-color: #ffffff;
    border: 1px solid #454546;
    color: #3a3a3b;
    margin-bottom: 20px
}

.style_sl_facebook {
    background-color: #3b5998;
    border: 1px solid #3b5998;
    color: #fff;
    margin-bottom: 20px
}

.style_sl_linkedin {
    background-color: #0077b5;
    border: 1px solid #0077b5;
    color: #fff;
    margin-bottom: 20px
}

.style_slbtn {
    border-radius: .25rem;
    cursor: pointer;
    margin-right: 1rem;
    padding: .5rem 1.125rem;
}

.sign-up-text {
    margin-top: 20px;
}

.image {
    width: 100%;
}

.align-items-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

/* progress loader */
.loading {
    display: flex;
    justify-content: center;

}

.loading--full-height {
    align-items: center;
    height: 100%;
}

.loading::after {
    content: "";
    width: 25px;
    height: 25px;
    border: 4px solid #dddddd;
    border-top-color: #3a267c;
    border-radius: 50%;
    transform: rotate(0.16turn);
    animation: loading 1s ease infinite;
}

@keyframes loading {

    /* Safari support */
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}
</style>
