
export default {
    // baseUrl :'http://172.16.0.51:8000/api/',
    // baseUrl: 'https://2f34-152-57-200-81.in.ngrok.io/api/',
    // baseUrl: 'http://127.0.0.1:8000/api/',
    // baseUrl: 'http://174.129.163.75:8000/api/',   // production
    // baseUrl: 'https://playonomics.com/api/',   // production
    // gupshupbaseUrl: 'https://api.gupshup.io/sm/api/v1/',
    // gupShupApiKey: 'clfecoappkpoc0dnthgrq5p6xvwdukro', 
    // gupShupAppName: 'Playonomic',
    // // gupShupNumber: 919353076831, // production
    // gupShupNumber: 917834811114,   // development
    // API_AUTHORIZATION: 'f296b19b575a4e8bb01db99239dcb5cbd070debc', // development
    // API_AUTHORIZATION: '05d268565cbb6abf1c562c1a68bf28baaeaa78c3', // production
    // gupShupApiKey: 'stfgv42qgnb6bjypsmct6j7lu7lfwfh1',   // test
    // gupShupAppName: 'TestWhatsapp0033',
    googleSignIn: 'Google',
    facebookSignIn: 'Facebook',
    linkedSignIn: 'LinkedIn',
    validRegex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
}