// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDkbMp3LSJyIJz21BFej4BUmaz4qJJz2Cc",
  authDomain: "x-playonomics.firebaseapp.com",
  projectId: "x-playonomics",
  storageBucket: "x-playonomics.appspot.com",
  messagingSenderId: "145766764956",
  appId: "1:145766764956:web:a6903171ac2506ae18b859",
  measurementId: "G-WBW3QM4FTP"
};

// Initialize Firebase
 /* eslint-disable */
const app = initializeApp(firebaseConfig);

// const nodemailer = require("nodemailer");
const analytics = getAnalytics(app);

export{
    app,
}