import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Signin.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../components/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue')
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import(/* webpackChunkName: "about" */ '../views/PhoneAuthView.vue')
  },
  {
    path: '/quiz',
    name: 'quiz',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuizQuestionsView.vue')
  },
  {
    path: '/result',
    name: 'result',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResultsView.vue')
  },
  {
    path: '/selection',
    name: 'selection',
    component: () => import(/* webpackChunkName: "about" */ '../views/BubbleSelectionView.vue')
  },
  {
    path: '/questions',
    name: 'question',
    component: () => import(/* webpackChunkName: "about" */ '../views/BubbleQuestionsView.vue')
  },
  {
    path: '/score',
    name: 'score',
    component: () => import(/* webpackChunkName: "about" */ '../views/ScoreView.vue'),
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    component: () => import(/* webpackChunkName: "about" */ '../views/VerifyEmailView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminView.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResetPassword.vue')
  },
  {
    path: '/learning',
    name: 'learning',
    component: () => import(/* webpackChunkName: "about" */ '../views/LearningView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue'),
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/terms&conditions',
    name: 'terms&conditions',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsAndConditions.vue'),
    meta: {
      hideNavbar: true,
    }
  },
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
}
})

export default router
