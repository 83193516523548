<template>
    <div :style="{ 'padding': deviceType == 'mobile' ? '30px' : '10px 30px' }" class="nav-bar">
        <a href="/">
            <div class="logo">
                <img src="../assets/logo_new.png" height="40" />
            </div>
        </a>

        <div :style="{ 'top': deviceType == 'mobile' ? '60px' : '' }"
            style="height: 40px;font-size: 14px;display: flex;flex-direction: row;position: absolute;right: 30px;">
            <p> <a class="route-anchor" href="/learning">Learnings</a></p>
            <button @click="$router.push('/authentication')" style="width: 90px; height: 35px; border: none;"
                type="submit" class="login-button">Log In</button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Footer-vue',
    data() {
        return {
            deviceType: window.innerWidth <= 800 ? 'mobile' : 'desktop',
        }
    }
}
</script>